<template>
  <div>
   <base-header class="pb-1">
       <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Relatório Operacional</h6>
        </div>
      </div>
    </base-header>

   <div class="container-fluid mt--6">
    <form>
        <card class="d-print-none">
            <h3 slot="header" class="mb-0">Relatório Operacional</h3>
            <div class="form-row mt-3">
                <div class="col-md-6">
                    <base-input addon-left-icon="ni ni-calendar-grid-58" label="Data Inicio">
                        <flat-picker slot-scope="{focus, blur}"
                                    @on-open="focus"
                                    @on-close="blur"
                                    :config="{allowInput: true, dateFormat: 'd/m/Y', altFormat: 'M j, Y'}"
                                    class="form-control datepicker"
                                    placeholder="Data Inicial"
                                    v-model="reportFilter.dateBegin">
                        </flat-picker>
                    </base-input>
                </div>   
                <div class="col-md-6">
                    <base-input addon-left-icon="ni ni-calendar-grid-58" label="Data Final">
                        <flat-picker slot-scope="{focus, blur}"
                                    @on-open="focus"
                                    @on-close="blur"
                                    :config="{allowInput: true, dateFormat: 'd/m/Y', altFormat: 'M j, Y'}"
                                    class="form-control datepicker"
                                    placeholder="Data Final"
                                    v-model="reportFilter.dateEnd">
                        </flat-picker>
                    </base-input>
                </div>
            </div>    
            <div class="col-md-12 text-right mb-3">
                <base-button type="secundary" native-type="button" @click="printReport"><i class="fas fa-print mr-1"></i>Imprimir</base-button>
                <base-button type="secundary" native-type="button" @click="exportReport"><i class="far fa-file-excel mr-1"></i>Exportar</base-button>
                <base-button type="primary" native-type="button" @click="filterReport"><i class="fas fa-funnel-dollar mr-1"></i> Filtrar</base-button>
            </div>
        </card>

        <div class="col-md-12">
                <card>
                <h5 class="text-center mb-2">Encontradas <strong>{{registryFound}}</strong> assinaturas</h5> 
                <table class="table table-responsive font-tabela-peq">
                    <thead class="thead">
                        <tr>
                            <th scope="col">Assinatura</th>
                            <th scope="col">Titular</th>
                            <th scope="col">Celular</th>
                            <th scope="col">Telefone</th>
                            <th scope="col">Dt. Registro</th>
                            <th scope="col">Dt. Primeiro PGTO</th>
                            <th scope="col">Tot. Vidas</th>
                            <th scope="col">Plano</th>
                            <th scope="col">Tp. PGTO</th>
                            <th scope="col">Cartão Informado</th>
                            <th scope="col">Preço</th>
                            <th scope="col">Assinante</th>
                            <th scope="col">Perfil</th>
                            <th scope="col">Local</th>
                            <th scope="col">Corretor</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="report in reportResult" :key="report.subscription">
                            <td>{{report.subscription}}</td>
                            <td><a href="javascript:void(0);" @click="openSubscription(report.subscription)">{{report.holderName}}</a></td>
                            <td>{{report.cellphone}}</td>
                            <td>{{report.homephone}}</td>
                            <td>{{report.registry}}</td>
                            <td>{{report.firstPayment}}</td>
                            <td>{{report.totalLife}}</td>
                            <td>{{report.planName}}</td>
                            <td>{{report.paymentType}}</td>
                            <td>{{report.noCard}}</td>
                            <td>{{report.price}}</td>
                            <td>{{report.user}}</td>
                            <td>{{report.profile}}</td>
                            <td>{{report.place}}</td>
                            <td>{{report.broker}}</td>
                            <td>{{report.status}}</td>
                        </tr>
                        <tr v-show="reportResult.length == 0">
                            <td colspan="8">
                               <center> Nenhuma informação disponível no relatório para o período informado.</center>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </card> 
             </div>   
        </form>   
   </div> <!--- fecha conteudo -->

  </div>
</template>

<script>
import { Select, Option } from 'element-ui'
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import FileSaver from 'file-saver'

export default {
  components: {
      [Select.name]: Select,
      [Option.name]: Option,
      flatPicker
  },
  data() {
    return {
        registryFound: 0,
        reportResult : [],
        reportFilter:{
            dateBegin: null,
            dateEnd: null
        }
    }
  },
  methods: {
      printReport(){
          window.print()
      },
      exportReport(){
            this.$clubApi.post('/report/operacional/export', this.reportFilter, {
               responseType : 'blob'
            }).then((response) => {
                var blob = new Blob([response.data], {
                    type: 'application/vnd.ms-excel'
                });
                FileSaver.saveAs(blob, 'relatorio-operacional.xlsx');
            }) .catch((error) => {
                this.$notify({type: 'warning', message: error.response.data.msg})
            }).finally(() =>{
                NProgress.done() 
            })
      },
      openSubscription(id){
          location.href = '/assinatura/'+id
      },
      filterReport(){
        this.$clubApi.post('/report/operacional', this.reportFilter)
        .then((response) => {
            this.reportResult = response.data.object
            if(response.data.object != null){
                this.registryFound = response.data.object.length
            }else{
                this.registryFound = 0
            }
        }).catch((error) => {
           this.$notify({type: 'warning', message: error.response.data.msg})
        }).finally(() =>{
            NProgress.done() 
        })
      }
  }
};
</script>

<style>
</style>